<script setup lang="ts">
import { PageName } from "~/utils/enum/page_name";
import { PublicAsset } from "~/utils/enum/public_asset";
import { useResolveRoute } from "~/composables/route";

const router = useRouter();
const route = useRoute();
const nuxtErr = useError();
const nuxtApp = useNuxtApp();

async function goTo() {
  if (!route.name) {
    navigateTo({
      name: PageName.Home.toString(),
    });
    return;
  }

  if (
    (window.history.state.back === "/auth/register" &&
      window.history.state.forward === "/auth/register") ||
    (window.history.state.back === "/auth/login" &&
      window.history.state.forward === "/auth/login") ||
    (window.history.state.back === "/auth/recovery" &&
      window.history.state.forward === "/auth/recovery")
  ) {
    // TODO Добавить счетчик для возврата
    router.push({ name: PageName.Home.toString() });
    return;
  }

  if (window.history.length > 1 && window.history.state.position > 0) {
    router.back();
    return;
  }

  router.push({ name: PageName.Home.toString() });
}

const getIsHeaderHidden = computed(
  () =>
    route.name === PageName.AuthLogout ||
    route.name === PageName.Redirect ||
    nuxtErr.value != undefined,
);

async function onLogoClick() {
  if (nuxtErr.value) {
    await useResolveRoute(
      {
        name: PageName.Home,
      },
      nuxtApp,
    );

    return;
  }

  await useResolveRoute(
    {
      name: getIsHeaderHidden.value ? route.name : PageName.Home.toString(),
      params: route.params,
      query: route.query,
    },
    nuxtApp,
  );
}
</script>

<template>
  <div class="min-h-screen flex items-center justify-center overlay py-28">
    <div class="gradient" />

    <UContainer
      class="flex gap-4 items-center justify-center w-full absolute top-6"
    >
      <div :class="['cursor-pointer max-w-[220px]']" @click="onLogoClick">
        <Image :src="PublicAsset.AlvimaLogo" alt="Алвима" loading="lazy" />
      </div>
      <div />
    </UContainer>

    <slot />
  </div>
</template>

<style scoped>
.gradient {
  position: fixed;
  inset: 0;
  pointer-events: none;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(0, 0, 0, 20%) 0,
    #fff 100%
  );
}

.overlay {
  background-size: 100px 100px;
  background-image:
    linear-gradient(to right, var(--ui-bg-accented) 1px, transparent 1px),
    linear-gradient(to bottom, var(--ui-bg-accented) 1px, transparent 1px);
}
</style>
